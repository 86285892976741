/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];
  let wcmmode = $.globals["wcmmode"];

  (function _template_src_main_webpack_repository_apps_conad_saporie_templates_st23_map_marker_st23_map_marker_html(){ 
    let render;
    render = function* _template_src_main_webpack_repository_apps_conad_saporie_templates_st23_map_marker_st23_map_marker_html_render(args) { 
      let uniqueid = args[1]['uniqueId'] || '';
      let extraclasses = args[1]['extraClasses'] || '';
      let card = args[1]['card'] || '';
      let $t, $n = args[0];
      $.dom.text($n,"\n    ");
      let cardtpl = $.template('src/main/webpack/repository/apps/conad-saporie/templates/st22-poi-map-card/st22-poi-map-card.html');
      $.dom.text($n,"\n\n    ");
      $t = $.dom.create("div",false,false);
      const var_attrValue0 = (uniqueid) + ("-marker");
      if (!$.col.empty(var_attrValue0)) {
        $.dom.attr($t, 'id', var_attrValue0, 'attribute');
      }
      const var_attrValue1 = ("st23-map-marker ") + (extraclasses);
      if (!$.col.empty(var_attrValue1)) {
        $.dom.attr($t, 'class', var_attrValue1, 'attribute');
      }
      const var_attrValue2 = card["mapId"];
      if (!$.col.empty(var_attrValue2)) {
        $.dom.attr($t, 'data-map-id', var_attrValue2, 'attribute');
      }
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n        ");
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "st23-map-marker__pin", 'attribute');
      $n = $.dom.push($n,$t);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n        ");
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "st23-map-marker__popup", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n            ");
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "st23-map-marker__popup-anchor", 'attribute');
      $n = $.dom.push($n,$t);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      yield $.call(cardtpl["render"], [$n, {"uniqueId": uniqueid, "extraClasses": "", "card": card, }]);
      $.dom.text($n,"\n        ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n    ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n\n");
    };
    $.template('src/main/webpack/repository/apps/conad-saporie/templates/st23-map-marker/st23-map-marker.html', 'render', render);
  })();
  (function _template_src_main_webpack_repository_apps_conad_saporie_templates_st22_poi_map_card_st22_poi_map_card_html(){ 
    let render;
    render = function* _template_src_main_webpack_repository_apps_conad_saporie_templates_st22_poi_map_card_st22_poi_map_card_html_render(args) { 
      let uniqueid = args[1]['uniqueId'] || '';
      let extraclasses = args[1]['extraClasses'] || '';
      let moblarge = args[1]['mobLarge'] || '';
      let discovermorelabel = args[1]['discoverMoreLabel'] || '';
      let card = args[1]['card'] || '';
      let sharesontop = args[1]['sharesOnTop'] || '';
      let $t, $n = args[0];
      $.dom.text($n,"\n    ");
      let restaurantinfo = $.template('src/main/webpack/repository/apps/conad-saporie/templates/st21-poi-info/st21-poi-info.html');
      $.dom.text($n,"\n    ");
      let picture = $.template('src/main/webpack/repository/apps/conad-saporie/templates/st12-picture/st12-picture.html');
      $.dom.text($n,"\n\n    \n    ");
      const link = card["link"];
      if (link) {
      }
      $.dom.text($n,"\n    ");
      const path = card["path"];
      if (path) {
      }
      $.dom.text($n,"\n    ");
      const title = card["title"];
      if (title) {
      }
      $.dom.text($n,"\n    ");
      const image = card["image"];
      if (image) {
      }
      $.dom.text($n,"\n    ");
      const breadcrumb = card["region"];
      if (breadcrumb) {
      }
      $.dom.text($n,"\n    ");
      const address = card["address"];
      if (address) {
      }
      $.dom.text($n,"\n    ");
      const url = (card["url"]) || (card["path"]);
      if (url) {
      }
      $.dom.text($n,"\n    ");
      const urlsite = card["urlSite"];
      if (urlsite) {
      }
      $.dom.text($n,"\n    ");
      const urllocation = card["urlLocation"];
      if (urllocation) {
      }
      $.dom.text($n,"\n    ");
      const urlsitelabel = card["urlSiteLabel"];
      if (urlsitelabel) {
      }
      $.dom.text($n,"\n    ");
      const urllocationlabel = card["urlLocationLabel"];
      if (urllocationlabel) {
      }
      $.dom.text($n,"\n\n    ");
      $t = $.dom.create("div",false,false);
      const var_attrValue0 = (uniqueid) + ("-article-card");
      if (!$.col.empty(var_attrValue0)) {
        $.dom.attr($t, 'id', var_attrValue0, 'attribute');
      }
      const var_attrValue1 = ("st22-poi-map-card ") + (extraclasses) + (" ") + (moblarge ? "st22-poi-map-card--mobileLarge" : "");
      if (!$.col.empty(var_attrValue1)) {
        $.dom.attr($t, 'class', var_attrValue1, 'attribute');
      }
      const var_attrValue2 = path;
      if (!$.col.empty(var_attrValue2)) {
        $.dom.attr($t, 'data-path', var_attrValue2, 'attribute');
      }
      const var_attrValue3 = card["mapId"];
      if (!$.col.empty(var_attrValue3)) {
        $.dom.attr($t, 'data-map-id', var_attrValue3, 'attribute');
      }
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n        \n        ");
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "st22-poi-map-card__content", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n            ");
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "st22-poi-map-card__media-container", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "st22-poi-map-card__media", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                    ");
      $t = $.dom.create("a",false,false);
      const var_attrMap4 = link["hashMap"];
      const var_attrName_id5 = "id";
      const var_mapContains_id6 = var_attrMap4["id"];
      if (!$.col.empty(var_mapContains_id6)) {
        $.dom.attr($t, var_attrName_id5, var_mapContains_id6, 'attribute');
      }
      if ((var_mapContains_id6) == (null)) {
        const var_attrValue7 = (uniqueid) + ("-media");
        if (!$.col.empty(var_attrValue7)) {
          $.dom.attr($t, 'id', var_attrValue7, 'attribute');
        }
      }
      const var_ignoredAttributes8 = {"id": true, };
      for (const var_attrIndex11 of $.col.keys(var_attrMap4)) {
        const var_attrName9 = $.col.get(var_attrMap4, var_attrIndex11);
        const var_attrNameEscaped10 = yield $.xss(var_attrName9, "attributeName");
        if (var_attrNameEscaped10) {
          if (!var_ignoredAttributes8[var_attrName9]) {
            const var_attrContent12 = var_attrMap4[var_attrName9];
            if (!$.col.empty(var_attrContent12)) {
              $.dom.attr($t, var_attrNameEscaped10, var_attrContent12, 'attribute');
            }
          }
        }
      }
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                        ");
      const var_testVariable13 = image;
      if (var_testVariable13) {
        $.dom.text($n,"\n                            ");
        yield $.call(picture["render"], [$n, {"uniqueId": uniqueid, "extraClasses": "st22-poi-map-card__pic", "image": image, "dsk": "w352h240", "tab": "w352h240", "mob": "w160h111", "lazy": true, "objectFit": "cover", "objectPosition": "center center", }]);
        $.dom.text($n,"\n                        ");
      }
      $.dom.text($n,"\n                    ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n                ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n\n        \n            ");
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "st22-poi-map-card__texts", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n            ");
      const var_testVariable14 = breadcrumb;
      if (var_testVariable14) {
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "st22-poi-map-card__breadcrumb", 'attribute');
        $n = $.dom.push($n,$t);
        const var_15 = yield $.xss(breadcrumb, "html");
        $.dom.append($n, var_15);
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n                ");
      $t = $.dom.create("a",false,false);
      const var_attrMap16 = link["hashMap"];
      const var_attrName_id17 = "id";
      const var_mapContains_id18 = var_attrMap16["id"];
      if (!$.col.empty(var_mapContains_id18)) {
        $.dom.attr($t, var_attrName_id17, var_mapContains_id18, 'attribute');
      }
      if ((var_mapContains_id18) == (null)) {
        const var_attrValue19 = (link["uniqueId"]) + ("-title");
        if (!$.col.empty(var_attrValue19)) {
          $.dom.attr($t, 'id', var_attrValue19, 'attribute');
        }
      }
      const var_attrName_class20 = "class";
      const var_mapContains_class21 = var_attrMap16["class"];
      if (!$.col.empty(var_mapContains_class21)) {
        $.dom.attr($t, var_attrName_class20, var_mapContains_class21, 'attribute');
      }
      if ((var_mapContains_class21) == (null)) {
        $.dom.attr($t, 'class', "st22-poi-map-card__title", 'attribute');
      }
      const var_ignoredAttributes22 = {"id": true, "class": true, };
      for (const var_attrIndex25 of $.col.keys(var_attrMap16)) {
        const var_attrName23 = $.col.get(var_attrMap16, var_attrIndex25);
        const var_attrNameEscaped24 = yield $.xss(var_attrName23, "attributeName");
        if (var_attrNameEscaped24) {
          if (!var_ignoredAttributes22[var_attrName23]) {
            const var_attrContent26 = var_attrMap16[var_attrName23];
            if (!$.col.empty(var_attrContent26)) {
              $.dom.attr($t, var_attrNameEscaped24, var_attrContent26, 'attribute');
            }
          }
        }
      }
      $n = $.dom.push($n,$t);
      $.dom.text($n," ");
      const var_27 = yield $.xss(title, "html");
      $.dom.append($n, var_27);
      $.dom.text($n," ");
      const var_28 = yield $.xss(card["mapId"], "html");
      $.dom.append($n, var_28);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n                ");
      const var_testVariable29 = address;
      if (var_testVariable29) {
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "st22-poi-map-card__address", 'attribute');
        $n = $.dom.push($n,$t);
        const var_30 = yield $.xss(address, "html");
        $.dom.append($n, var_30);
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n        ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      yield $.call(restaurantinfo["render"], [$n, {"uniqueId": uniqueid, "extraClasses": "st22-poi-map-card__info", "urlSite": urlsite, "urlLocation": urllocation, "urlSiteLabel": urlsitelabel, "urlLocationLabel": urllocationlabel, }]);
      $.dom.text($n,"\n            ");
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "st22-poi-map-card__separator", 'attribute');
      $n = $.dom.push($n,$t);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "st22-poi-map-card__appr", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("a",false,false);
      const var_attrMap31 = link["hashMap"];
      const var_attrName_id32 = "id";
      const var_mapContains_id33 = var_attrMap31["id"];
      if (!$.col.empty(var_mapContains_id33)) {
        $.dom.attr($t, var_attrName_id32, var_mapContains_id33, 'attribute');
      }
      if ((var_mapContains_id33) == (null)) {
        const var_attrValue34 = link["uniqueId"];
        if (!$.col.empty(var_attrValue34)) {
          $.dom.attr($t, 'id', var_attrValue34, 'attribute');
        }
      }
      const var_ignoredAttributes35 = {"id": true, };
      for (const var_attrIndex38 of $.col.keys(var_attrMap31)) {
        const var_attrName36 = $.col.get(var_attrMap31, var_attrIndex38);
        const var_attrNameEscaped37 = yield $.xss(var_attrName36, "attributeName");
        if (var_attrNameEscaped37) {
          if (!var_ignoredAttributes35[var_attrName36]) {
            const var_attrContent39 = var_attrMap31[var_attrName36];
            if (!$.col.empty(var_attrContent39)) {
              $.dom.attr($t, var_attrNameEscaped37, var_attrContent39, 'attribute');
            }
          }
        }
      }
      $n = $.dom.push($n,$t);
      const var_40 = yield $.xss((discovermorelabel) || ("Approfondisci"), "html");
      $.dom.append($n, var_40);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n\n    ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n");
    };
    $.template('src/main/webpack/repository/apps/conad-saporie/templates/st22-poi-map-card/st22-poi-map-card.html', 'render', render);
  })();
  (function _template_src_main_webpack_repository_apps_conad_saporie_templates_st21_poi_info_st21_poi_info_html(){ 
    let render;
    render = function* _template_src_main_webpack_repository_apps_conad_saporie_templates_st21_poi_info_st21_poi_info_html_render(args) { 
      let uniqueid = args[1]['uniqueId'] || '';
      let extraclasses = args[1]['extraClasses'] || '';
      let urlsite = args[1]['urlSite'] || '';
      let urllocation = args[1]['urlLocation'] || '';
      let urlsitelabel = args[1]['urlSiteLabel'] || '';
      let urllocationlabel = args[1]['urlLocationLabel'] || '';
      let $t, $n = args[0];
      $.dom.text($n,"\n    ");
      $t = $.dom.create("div",false,false);
      const var_attrValue0 = (uniqueid) + ("-restInfo");
      if (!$.col.empty(var_attrValue0)) {
        $.dom.attr($t, 'id', var_attrValue0, 'attribute');
      }
      const var_attrValue1 = ("st21-poi-info ") + (extraclasses);
      if (!$.col.empty(var_attrValue1)) {
        $.dom.attr($t, 'class', var_attrValue1, 'attribute');
      }
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n        ");
      const var_testVariable2 = urlsite;
      if (var_testVariable2) {
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "st21-poi-info__goto", 'attribute');
        $n = $.dom.push($n,$t);
        $.dom.text($n,"\n            ");
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "st21-poi-info__gotoSiteIcon", 'attribute');
        $n = $.dom.push($n,$t);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n            ");
        $t = $.dom.create("a",false,false);
        $.dom.attr($t, 'class', "st21-poi-info__gotoSiteLabel", 'attribute');
        const var_attrValue3 = urlsite;
        if (!$.col.empty(var_attrValue3)) {
          $.dom.attr($t, 'href', var_attrValue3, 'attribute');
        }
        $n = $.dom.push($n,$t);
        const var_4 = yield $.xss((urlsitelabel) || ("Visita il sito"), "html");
        $.dom.append($n, var_4);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n        ");
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n        ");
      const var_testVariable5 = urllocation;
      if (var_testVariable5) {
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "st21-poi-info__location", 'attribute');
        $n = $.dom.push($n,$t);
        $.dom.text($n,"\n            ");
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "st21-poi-info__locationIcon", 'attribute');
        $n = $.dom.push($n,$t);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n            ");
        $t = $.dom.create("a",false,false);
        $.dom.attr($t, 'class', "st21-poi-info__locationLabel", 'attribute');
        const var_attrValue6 = urllocation;
        if (!$.col.empty(var_attrValue6)) {
          $.dom.attr($t, 'href', var_attrValue6, 'attribute');
        }
        $n = $.dom.push($n,$t);
        const var_7 = yield $.xss((urllocationlabel) || ("Ottieni indicazioni"), "html");
        $.dom.append($n, var_7);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n        ");
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n    ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n");
    };
    $.template('src/main/webpack/repository/apps/conad-saporie/templates/st21-poi-info/st21-poi-info.html', 'render', render);
  })();
  (function _template_src_main_webpack_repository_apps_conad_saporie_templates_st12_picture_st12_picture_html(){ 
    let render;
    render = function* _template_src_main_webpack_repository_apps_conad_saporie_templates_st12_picture_st12_picture_html_render(args) { 
      let uniqueid = args[1]['uniqueId'] || '';
      let extraclasses = args[1]['extraClasses'] || '';
      let image = args[1]['image'] || '';
      let dsk = args[1]['dsk'] || '';
      let tab = args[1]['tab'] || '';
      let mob = args[1]['mob'] || '';
      let lazy = args[1]['lazy'] || '';
      let objectfit = args[1]['objectFit'] || '';
      let objectposition = args[1]['objectPosition'] || '';
      let $t, $n = args[0];
      $.dom.text($n,"\n\n    ");
      const dsk2x = $.exec("format", "{0}2x", {"format": [dsk], });
      if (dsk2x) {
      }
      $.dom.text($n,"\n    ");
      const tab2x = $.exec("format", "{0}2x", {"format": [tab], });
      if (tab2x) {
      }
      $.dom.text($n,"\n    ");
      const mob2x = $.exec("format", "{0}2x", {"format": [mob], });
      if (mob2x) {
      }
      $.dom.text($n,"\n\n    \n    ");
      const var_testVariable0 = ((image["alt"]) && (wcmmode["disabled"])) && (lazy);
      if (var_testVariable0) {
        $t = $.dom.create("picture",false,false);
        const var_attrValue1 = (uniqueid) + ("-picture");
        if (!$.col.empty(var_attrValue1)) {
          $.dom.attr($t, 'id', var_attrValue1, 'attribute');
        }
        const var_attrValue2 = ("st12-picture st12-picture--lazy ") + (extraclasses) + (" st12-picture--") + (objectfit);
        if (!$.col.empty(var_attrValue2)) {
          $.dom.attr($t, 'class', var_attrValue2, 'attribute');
        }
        $n = $.dom.push($n,$t);
        $.dom.text($n,"\n        ");
        $t = $.dom.create("source",true,true);
        $.dom.attr($t, 'media', "(max-width: 767px)", 'attribute');
        const var_attrValue3 = ((image["mobile"]) || (image[mob])) + (" 1x, ") + ((image["mobile2x"]) || (image[mob2x])) + (" 2x");
        if (!$.col.empty(var_attrValue3)) {
          $.dom.attr($t, 'data-srcset', var_attrValue3, 'attribute');
        }
        $.dom.attr($t, 'type', "image/jpeg", 'attribute');
        $n = $.dom.push($n,$t);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n        ");
        $t = $.dom.create("source",true,true);
        $.dom.attr($t, 'media', "(max-width: 991px)", 'attribute');
        const var_attrValue4 = ((image["tablet"]) || (image[tab])) + (" 1x, ") + ((image["tablet2x"]) || (image[tab2x])) + (" 2x");
        if (!$.col.empty(var_attrValue4)) {
          $.dom.attr($t, 'data-srcset', var_attrValue4, 'attribute');
        }
        $.dom.attr($t, 'type', "image/jpeg", 'attribute');
        $n = $.dom.push($n,$t);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n        ");
        $t = $.dom.create("source",true,true);
        const var_attrValue5 = ((image["desktop"]) || (image[dsk])) + (" 1x, ") + ((image["deskop2x"]) || (image[dsk2x])) + (" 2x");
        if (!$.col.empty(var_attrValue5)) {
          $.dom.attr($t, 'data-srcset', var_attrValue5, 'attribute');
        }
        $.dom.attr($t, 'type', "image/jpeg", 'attribute');
        $n = $.dom.push($n,$t);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n        ");
        $t = $.dom.create("img",true,true);
        $.dom.attr($t, 'class', "st12-picture__img", 'attribute');
        const var_attrValue6 = ("object-position: ") + (yield $.xss(objectposition, "unsafe"));
        if (!$.col.empty(var_attrValue6)) {
          $.dom.attr($t, 'style', var_attrValue6, 'attribute');
        }
        $.dom.attr($t, 'src', "data:image/gif;base64,R0lGODlhAwACAIAAAP///wAAACH5BAEAAAEALAAAAAADAAIAAAICjF8AOw==", 'attribute');
        const var_attrValue7 = (image["desktop"]) || (image[dsk]);
        if (!$.col.empty(var_attrValue7)) {
          $.dom.attr($t, 'data-src', var_attrValue7, 'attribute');
        }
        const var_attrValue8 = image["alt"];
        if (!$.col.empty(var_attrValue8)) {
          $.dom.attr($t, 'alt', var_attrValue8, 'attribute');
        }
        $n = $.dom.push($n,$t);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n    ");
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n\n    \n    ");
      const var_testVariable9 = (image["alt"]) && (((!wcmmode["disabled"]) || (!lazy)));
      if (var_testVariable9) {
        $t = $.dom.create("picture",false,false);
        const var_attrValue10 = (uniqueid) + ("-picture");
        if (!$.col.empty(var_attrValue10)) {
          $.dom.attr($t, 'id', var_attrValue10, 'attribute');
        }
        const var_attrValue11 = ("st12-picture ") + (extraclasses) + (" st12-picture--") + (objectfit);
        if (!$.col.empty(var_attrValue11)) {
          $.dom.attr($t, 'class', var_attrValue11, 'attribute');
        }
        $n = $.dom.push($n,$t);
        $.dom.text($n,"\n        ");
        $t = $.dom.create("source",true,true);
        $.dom.attr($t, 'media', "(max-width: 767px)", 'attribute');
        const var_attrValue12 = ((image["mobile"]) || (image[mob])) + (" 1x, ") + ((image["mobile2x"]) || (image[mob2x])) + (" 2x");
        if (!$.col.empty(var_attrValue12)) {
          $.dom.attr($t, 'srcset', var_attrValue12, 'attribute');
        }
        $.dom.attr($t, 'type', "image/jpeg", 'attribute');
        $n = $.dom.push($n,$t);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n        ");
        $t = $.dom.create("source",true,true);
        $.dom.attr($t, 'media', "(max-width: 991px)", 'attribute');
        const var_attrValue13 = ((image["tablet"]) || (image[tab])) + (" 1x, ") + ((image["tablet2x"]) || (image[tab2x])) + (" 2x");
        if (!$.col.empty(var_attrValue13)) {
          $.dom.attr($t, 'srcset', var_attrValue13, 'attribute');
        }
        $.dom.attr($t, 'type', "image/jpeg", 'attribute');
        $n = $.dom.push($n,$t);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n        ");
        $t = $.dom.create("source",true,true);
        const var_attrValue14 = ((image["desktop"]) || (image[dsk])) + (" 1x, ") + ((image["deskop2x"]) || (image[dsk2x])) + (" 2x");
        if (!$.col.empty(var_attrValue14)) {
          $.dom.attr($t, 'srcset', var_attrValue14, 'attribute');
        }
        $.dom.attr($t, 'type', "image/jpeg", 'attribute');
        $n = $.dom.push($n,$t);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n        ");
        $t = $.dom.create("img",true,true);
        $.dom.attr($t, 'class', "st12-picture__img", 'attribute');
        const var_attrValue15 = ("object-position: ") + (yield $.xss(objectposition, "unsafe"));
        if (!$.col.empty(var_attrValue15)) {
          $.dom.attr($t, 'style', var_attrValue15, 'attribute');
        }
        const var_attrValue16 = (image["desktop"]) || (image[dsk]);
        if (!$.col.empty(var_attrValue16)) {
          $.dom.attr($t, 'src', var_attrValue16, 'attribute');
        }
        const var_attrValue17 = image["alt"];
        if (!$.col.empty(var_attrValue17)) {
          $.dom.attr($t, 'alt', var_attrValue17, 'attribute');
        }
        $n = $.dom.push($n,$t);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n    ");
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n");
    };
    $.template('src/main/webpack/repository/apps/conad-saporie/templates/st12-picture/st12-picture.html', 'render', render);
  })();

  return $.run(function* () {
    let $t, $n = $.dom.start();
    $.dom.text($n,"\n");
    let marker = $.template('src/main/webpack/repository/apps/conad-saporie/templates/st23-map-marker/st23-map-marker.html');
    $.dom.text($n,"  \n");
    yield $.call(marker["render"], [$n, {"uniqueId": data["uniqueId"], "extraClasses": data["extraClasses"], "card": data["card"], }]);
    $.dom.text($n,"\n");
    return $.dom.end();

  });
};
