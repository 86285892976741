import Component from '../../../../../../libs/components/component';
import { register } from '../../../../../../libs/register';
import { getTrackingManager } from '../../../../../../libs/tracking-manager';
import { getUserService } from '../../../../../../libs/user-service';

class Page extends Component {
    constructor(name, root) {
        super(name, root);
        this.body = document.body;
        this.blockCounter = 0;
        this.trackingManager = getTrackingManager();
        this.coopPages = window.coopPages || [];
        const coopPagesRegex = window.coopPagesRegex || [];
        this.coopPagesRegex = coopPagesRegex.map((item) => new RegExp(item));
        this.userService = getUserService();

        if (this.body.classList.contains('akt-article-recipe')) {
            this.isArticle = true;
        }
        this._addEventListeners();
    }

    _addEventListeners() {
        ////////////////////////////////////////
        ///////////////////////////////////////
        //////////////// TRACKING INTERCEPTOR
        ////////////////////////////////////////
        this._addListener(
            'click',
            (event) => {
                const linkEl = event.target.closest('[data-link-tracking]');
                if (!linkEl) return;
                const options = JSON.parse(linkEl.dataset.linkTracking);
                this.trackingManager.trackLink(linkEl, options);
            },
            this.root,
            { capture: true }
        );

        ////////////////////////////////////////
        ///////////////////////////////////////
        //////////////// CLICK INTERCEPTOR
        ////////////////////////////////////////
        this._addListener(
            'click',
            (event) => {
                const target = event.target.closest('a[href]');
                if (!target) return;

                //////////////////////// CHECK COOP
                if (this._coopLink(event, target)) {
                    return;
                }
            },
            this.root
        );

        if (this.isArticle) {
            //first adjust
            setTimeout(() => {
                this._adjustAktArticleRecipeContent();
            }, 1000);
            //resize adjust
            window.addEventListener('resize', () => {
                setTimeout(() => {
                    this._adjustAktArticleRecipeContent();
                }, 1000);
            });
        }
    }

    _coopLink(event, target) {
        let link = null;
        const href = target.getAttribute('href');
        if (!href) return false;

        // check stanard coop pages
        for (const page of this.coopPages) {
            if (page == href) {
                link = target;
                break;
            }
        }
        // check regex
        for (const regex of this.coopPagesRegex) {
            if (href.match(regex)) {
                link = target;
                break;
            }
        }
        if (!link) return false;

        event.preventDefault();
        // redirect to coop
        this._redirectToCoop(link);
        return true;
    }

    async _redirectToCoop(link) {
        const urlParams = new URLSearchParams(window.location.search);
        const user = await this.userService.getUser();
        const coop = urlParams.get('coop') || user?.newMappedCooperative;
        if (coop) {
            const url = new URL(link.href, window.origin);
            url.searchParams.set('coop', coop?.toLowerCase());
            link.href = url.href;
            window.open(link.href, link.target);
        }
    }

    toggleBlockPageScroll() {
        const hasBlock = this.body.classList.contains('block-scroll');
        if (hasBlock) this.removeBlockScroll();
        else this.setBlockScroll();
    }

    setBlockScroll() {
        if (this.blockCounter > 0) {
            this.blockCounter++;
            return;
        }
        const bodyWidth = this.body.clientWidth;
        this.body.classList.add('block-scroll');
        const margin = this.body.clientWidth - bodyWidth;
        //set body margin
        this.body.style.marginRight = `${margin}px`;
        //set fixed margin
        const fixedEls = this.root.querySelectorAll('.is-fixed');
        for (const fix of fixedEls) {
            fix.style.marginRight = `${margin}px`;
        }
        this.blockCounter++;
    }

    removeBlockScroll() {
        if (this.blockCounter <= 0) return;
        this.blockCounter--;
        //remove block scroll only if block counter = 0 so there is only a 1 modal/popup open on page
        if (this.blockCounter > 0) return;

        this.body.classList.remove('block-scroll');
        //set body margin
        this.body.style.marginRight = 0;
        //set fixed margin
        const fixedEls = this.root.querySelectorAll('.is-fixed');
        for (const fix of fixedEls) {
            fix.style.marginRight = 0;
        }
    }

    _adjustAktArticleRecipeContent() {
        const BREAKPOINT_L = 992;

        const AKT_ARTICLE_RECIPE_SELECTORS = {
            content: '.akt-content',
            contentDoubleColumn: '.akt-content-double-column',
            childLeftTop: '.akt-content-double-column__left--top',
            childLeftBottom: '.akt-content-double-column__left--bottom',
            childRight: '.akt-content-double-column__right',
        };

        let parent = document.querySelector(AKT_ARTICLE_RECIPE_SELECTORS.contentDoubleColumn),
            childLeftTop = parent.querySelector(AKT_ARTICLE_RECIPE_SELECTORS.childLeftTop),
            childRight = parent.querySelector(AKT_ARTICLE_RECIPE_SELECTORS.childRight),
            childLeftBottom = parent.querySelector(AKT_ARTICLE_RECIPE_SELECTORS.childLeftBottom);

        let childLeftTop__height = childLeftTop.clientHeight,
            childRight__height = childRight.clientHeight;

        let childLeftBottom__marginTop = childLeftTop__height - childRight__height;
        if (window.innerWidth > BREAKPOINT_L && childLeftBottom && childLeftBottom__marginTop < 0) {
            childLeftBottom.style.marginTop = childLeftBottom__marginTop + 'px';
        } else {
            childLeftBottom.style.marginTop = '0px';
        }
    }
}

register.registerClass('.sp1-page', Page);

export const setBlockScroll = () => {
    const page = document.querySelector(`.sp1-page`);
    if (!page || !page.objReference) return;
    page.objReference.setBlockScroll();
};

export const removeBlockScroll = () => {
    const page = document.querySelector(`.sp1-page`);
    if (!page || !page.objReference) return;
    page.objReference.removeBlockScroll();
};

window.setBlockScroll = setBlockScroll;
window.removeBlockScroll = removeBlockScroll;
